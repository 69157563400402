let windowHeight = window.innerHeight;
let windowHeightExtra = 0;
const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const mobile = /Mobi/.test(navigator.userAgent);
if (safari && !mobile) {
    windowHeightExtra = window.outerHeight - window.innerHeight;
}
if (mobile) {
    windowHeight = window.screen.availHeight;
    windowHeightExtra = (window.screen.availHeight - window.innerHeight) / 2;
}

const positionParallax = (container, speed, parallax, elem) => {
    const bgScroll = container.top / speed - windowHeightExtra - windowHeight / speed;
    const parallaxElem = parallax[elem];
    parallaxElem.style.top = `${bgScroll}px`;
};

const animateParallax = (parallax, speed) => {
    for (let i = 0; parallax.length > i; i += 1) {
        const container = parallax[i].parentElement.parentElement.getBoundingClientRect();
        if (container.top + container.height >= 0 && container.top <= windowHeight) {
            positionParallax(container, speed, parallax, i);
        }
    }
};

const calculateHeight = (parallax, speed) => {
    for (let i = 0; parallax.length > i; i += 1) {
        const parallaxElem = parallax[i];
        const container = parallaxElem.parentElement.parentElement.getBoundingClientRect();
        const containerTop = parallaxElem.parentElement.parentElement.offsetTop;
        const elemOffsetTop = (windowHeight - container.height) / 2;
        const bgHeight = windowHeight > container.height + containerTop
            ? container.height + (elemOffsetTop - elemOffsetTop / speed) * 2
            : container.height + (elemOffsetTop - elemOffsetTop / speed) * 2;
        parallaxElem.style.height = `${bgHeight + windowHeightExtra * 2}px`;
        positionParallax(container, speed, parallax, i);
    }
};

function UniversalParallax() {
    const up = (parallax, speed) => {
        calculateHeight(parallax, speed);

        if (!mobile) {
            window.addEventListener('resize', () => {
                windowHeight = window.innerHeight;
                calculateHeight(parallax, speed);
            });
        }

        window.addEventListener('scroll', () => {
            animateParallax(parallax, speed);
        });
    };

    this.init = (args = {}) => {
        const param = {
            speed: typeof args.speed !== 'undefined' ? args.speed : 1.5,
            className: typeof args.className !== 'undefined' ? args.className : 'parallax',
        };
        const parallax = document.getElementsByClassName(param.className);

        for (let i = 0; parallax.length > i; i += 1) {
            const elem = parallax[i];
            const wrapper = document.createElement('div');
            elem.parentNode.insertBefore(wrapper, elem);
            wrapper.appendChild(elem);
            const parallaxContainer = elem.parentElement;
            parallaxContainer.className += 'parallax__container';
            const parentStyle = window.getComputedStyle(parallaxContainer.parentElement, null);
            if (parentStyle.getPropertyValue('position') !== 'relative') {
                parallaxContainer.parentElement.style.position = 'relative';
            }

            const imgData = elem.dataset.parallaxImage;
            if (typeof imgData !== 'undefined') {
                elem.style.backgroundImage = `url(${imgData})`;

                if (elem.classList.length === 1 && elem.classList[0] === 'parallax') {
                    elem.style.backgroundRepeat = 'no-repeat';
                    elem.style.backgroundPosition = 'center';
                    elem.style.backgroundSize = 'cover';
                }
            }
        }

        document.addEventListener('readystatechange', (event) => {
            if (event.target.readyState === 'complete') {
                up(parallax, param.speed);
            }
        });
    };
}

new UniversalParallax().init({ speed: 7 });
setInterval(() => {
    if (typeof window.AOS !== 'undefined') {
        window.AOS.refresh();
    }
}, 1e3 / 3);
