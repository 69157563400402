import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const container = $('#projects');
    if (!container.length) {
        return;
    }

    const subNav = container.find('.sub-nav--buttons');
    const links = subNav.find('.sub-nav__link');
    links.each((i, link) => {
        $(link).on('click', () => {
            const termID = $(link).attr('data-term-id');
            const $projectCards = $('.project-card');
            $projectCards.each((j, card) => {
                const $card = $(card);
                const terms = $card.attr('data-term-id').split(',');
                const $column = $card.parents('.content-column');
                if (termID && !terms.includes(termID)) {
                    $column.addClass('d-none');
                } else {
                    $column.removeClass('d-none');
                }
            });
        });
    });
});
