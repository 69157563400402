import { addAction, INIT } from '@situation/setdesign.util';

let heroAnimationBlocks;
addAction(INIT, () => {
    heroAnimationBlocks = document.querySelectorAll('.hero-animation');

    if (!heroAnimationBlocks) {
        return;
    }

    heroAnimationBlocks.forEach((heroBlock) => {
        const timing = heroBlock.dataset.heroSpeed ?? 300;
        const text = heroBlock.querySelectorAll('.hero-animation__text p');
        const links = heroBlock.querySelectorAll('.hero-animation__buttons');
        const hidingText = [...text, ...links];
        hidingText.forEach((hiddenText, index) => {
            const gap = index * Number(timing);
            setTimeout(() => {
                hiddenText.classList.add('visible');
            }, gap);
        });
    });
});
