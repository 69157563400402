import { addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (options, $instance) => ($instance.hasClass('content-slider--projects')
    ? {
        ...options,
        loop: false,
        responsive: {
            0: {
                items: 1, dots: true, stagePadding: 30, margin: 10, nav: false,
            },
            992: {
                items: 2, dots: false, margin: 16, stagePadding: 0, nav: true,
            },
            1200: {
                items: 3, dots: false, margin: 16, nav: true,
            },
        },
    }
    : options));
