import { addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (options, $instance) => ($instance.hasClass('content-slider--gallery')
    ? {
        ...options,
        margin: 10,
        padding: 0,
        stagePadding: 50,
        dots: true,
        loop: true,
        nav: false,
        center: true,
        items: 1,
        responsive: { 768: { stagePadding: 100, dots: false, nav: true } },
    }
    : options));
