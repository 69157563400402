import { addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (options, $instance) => ($instance.hasClass('content-slider--default')
    ? {
        ...options,
        responsive: {
            0: {
                items: 1,
                loop: false,
                dots: true,
                stagePadding: 10,
                margin: 10,
                nav: false,
            },
            992: {
                items: 2,
                loop: true,
                dots: false,
                stagePadding: 0,
                margin: 16,
                nav: true,
            },
            1200: {
                items: 3,
                loop: true,
                dots: false,
                stagePadding: 0,
                margin: 16,
                nav: true,
            },
        },
    }
    : options));
