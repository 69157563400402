import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

addFilter('nav-menu/lock-scroll', () => false);
addAction(INIT, () => {
    $('button.nav-menu__toggle').on('click', () => {
        if (!window.matchMedia('(min-width: 992px)').matches) {
            if (window.scrollY < jQuery('.header-nav').height()) {
                setTimeout(() => window.scrollTo(0, 0), 66);
            }
        }
    });
});
