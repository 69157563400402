import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const subNavs = $('.sub-nav');
    if (!subNavs.length) {
        return;
    }

    subNavs.each((i, el) => {
        const firstListItem = $(el).find('.sub-nav__item:first-child');
        if (firstListItem.length) {
            firstListItem.addClass('sub-nav__item--active');
        }
    });
});
